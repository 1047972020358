<template>
<div class="email-report">
  <h4>{{$t('generic-str.menu.management')}}</h4>
  <template v-if="fetched">
    <!-- Report table -->
    <div v-if="reports.length" class="show-reports">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">Email</th>
            <th scope="col">{{$tc('app.sent', 1)}}</th>
            <th scope="col">{{$tc('app.delivered', 1)}}</th>
            <th scope="col">{{$tc('app.accepted', 1)}}</th>
            <th scope="col">{{$t('app.opened')}}</th>
            <th scope="col">{{$tc('generic-str.status.lbl-bounced-o', 2)}}</th>
            <th scope="col">{{$t('generic-str.status.lbl-blocked-o')}}</th>
          </tr>
        </thead>
        <tbody>
          <report-total :item="total" />
          <report-item
              v-for="(report, index) in reports"
              :key="index"
              :item="report"
              :index="index" />
        </tbody>
      </table>
    </div>
    <template v-else>
      <LottieAnimNoData />
    </template>
    <!--/Report table -->
    <!-- <pagination v-model="currentPage" :total-pages="pages" @change="fetch" /> -->
  </template>
  <template v-else>
    <LoadingAnim />
  </template>
</div>
</template>
<script>
import ReportItem from '@/components/rebranding/mails/insights/ReportItem.vue';
import ReportTotal from '@/components/rebranding/mails/insights/ReportItemTotal.vue';
// import Pagination from '@/components/rebranding/pagination/Pagination.vue';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';
import LottieAnimNoData from '@/components/rebranding/animLottie/NoData.vue';

import axios from '@/plugins/axios';

const webApi = axios();

export default {
  name: 'EmailReport',
  components: {
    ReportItem,
    ReportTotal,
    LoadingAnim,
    LottieAnimNoData,
    // Pagination,
  },
  props: ['dateGte', 'dateLte'],
  watch: {
    dateGte(newVal, oldVal) {
      console.log('Date updated [dateGte]: ', newVal, ' | was: ', oldVal);
      // this.dateGte = newVal.replace(/\s/, 'T');
    },
    dateLte(newVal, oldVal) {
      console.log('Date updated [dateLte]: ', newVal, ' | was: ', oldVal);
      // this.dateLte = newVal.replace(/\s/, 'T');
      this.fetch();
    },
  },
  data() {
    return {
      currentPage: 1,
      pages: 1,
      fetched: true,
      reports: [],
      total: [],
    };
  },
  methods: {
    updatedProducts() {
      this.fetch();
    },
    fetch() {
      this.fetched = false;
      webApi.post(`/email/insights/?begin_date=${this.dateGte}&end_date=${this.dateLte}&time_window=hourly&direction=outbound`).then(
        (response) => {
          console.log('Product response:\n ', response.data);
          this.fetched = true;
          this.reports = response.data.detail;
          this.total = response.data.total;
        },
        (error) => {
          this.content = error;
        },
      );
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>
<style lang="scss" scoped>
.email-report {
  .title {
    margin-right: auto;
    font-weight: bold;
  }
}
</style>
