<template>
  <!-- Report item -->
    <tr v-if="item">
      <td v-if="item.name !== 'none'" v-b-popover.hover.topleft="`${$t('app.account_name')}: ${item.name}`">{{item.email}}</td>
      <td v-else>{{item.email}}</td>
      <td>{{item.sent}}</td>
      <td>{{item.delivered}}</td>
      <td>{{item.accepted}}</td>
      <td>{{item.opened}}</td>
      <td>{{item.bounced}}</td>
      <td>{{item.blocked}}</td>
    <!-- <code>{{item}}</code> -->
    </tr>
  <!--/Report item -->
</template>
<script>
import Swal from 'sweetalert2';
import axios from '@/plugins/axios';

const webApi = axios();

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    deleteProduct(pivotProdId) {
      Swal.fire({
        title: this.$t('workflow.workspace.products.swal.remove-product'),
        text: this.$t('workflow.workspace.products.swal.sure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
        cancelButtonText: this.$t('generic-str.no'),
      }).then((result) => {
        if (result.value) {
          webApi.put(`/pipelines/deals/${this.$route.params.id}`, {
            offerings: [
              {
                id: pivotProdId,
                attach: false,
              },
            ],
          }).then((response) => {
            console.log(`Product: delete ->\n${response}`);
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: 'Produto removido com sucesso!',
              type: 'success',
            });
            this.$emit('update');
          }).catch((ee) => {
            const content = ee?.response?.data?.message ?? String(ee);
            this.isSending = false;
            this.$toast.show({
              title: 'Comentário',
              content,
              type: 'danger',
            });
            this.$emit('update');
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.card-task {
  border-radius: 8px;
  flex: 0 0 31%;
  max-width: 31%;
  transition: 0.5s;
  margin-bottom: 0;
  height: fit-content;
  min-height: 210px;
  &:hover {
    transform: scale(1.02);
  }
  .card-header {
    display: flex;
    align-items: center;
    .options-task {
      margin-left: auto;
      .btn {
        width: fit-content;
        height: fit-content;
        padding: 0;
      }
      .material-symbols-outlined {
        border-radius: 50%;
        padding: 3px;
        &.done {
          background-color: var(--sent-bg) !important;
          border-color: var(--sent-fg) !important;
          color: var(--sent-fg) !important;
        }
        &.not-done {
          background-color: var(--urgent-bg) !important;
          border-color: var(--urgent-fg) !important;
          color: var(--urgent-fg) !important;
        }
      }
      ul {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-bottom: 0;
      }
    }
  }
  .card-body {
    padding-top: 0;
  }
  .collapse-preview a.collapsed:after {
    content: '+ Show More';
  }

  .collapse-preview a:not(.collapsed):after {
    content: '- Show Less';
  }

  .collapse-preview.collapse:not(.show) {
    display: block;
    /* height = lineheight * no of lines to display */
    height: 4.5em;
    overflow: hidden;
  }
  .backdrop-preview {
    display: none;
  }
  .collapse-preview.collapse:not(.show) ~ .backdrop-preview {
    position: relative;
    display: block;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    height: 30px;
    margin-top: -30px;
  }

  .collapse-preview.collapsing {
    height: 4.5em;
  }
}
button {
  right: 5px;
  top: 3px;
  padding: 0;
  height: fit-content;
  .delete-icon, .edit-icon {
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
    color: var(--gray-font-color-3);
    margin-left: auto;
    font-size: 18px;
    transition: 0.5s;

    &:hover,
    &:focus {
      opacity: 1;
      color: #d5273e;
      transform: scale(1.2);
    }
    &.edit-icon:hover,
    &.edit-icon:focus {
      opacity: 1;
      color: var(--normal-fg);
      transform: scale(1.2);
    }
  }
}
</style>
